@font-face {
    font-family: 'Audiowide';
    src: url("./assets/fonts/Audiowide-Regular.ttf");
    font-weight: normal;
    font-display: auto;
}

* {
    font-family: 'Audiowide', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    overflow: hidden;
}

.container {
    max-width: 1730px;
    margin: 0 auto;
}
