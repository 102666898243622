@keyframes glowing {
  0% {
    box-shadow: 0 0 10px rgba(182, 148, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 100px rgba(182, 148, 255, 1),
    0 0 900px rgba(182, 148, 255, 1);
  }
}

@keyframes pulsating {
  0% {
    height: 30px;
  }
  100% {
    height: 35px;
  }
}

.soundIcon {
  z-index: 10;
  margin: auto;
  position: relative;
  height: 30px;
  animation: pulsating 0.5s infinite alternate;
}

.home {
  overflow: scroll !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 200px 0;
  width: 100%;
  height: 100vh;
  background: url("../../assets/img/black-hole-big.png") no-repeat center center;
  background-size: cover;
  gap: 2rem;
}

.glow-container {
  position: absolute;
  display: flex;
  align-items: center;
  top: 3.4rem;
  width: 455px;
  height: 460px;
  overflow: visible !important;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(0, 128, 255, 0.8);
  transition: box-shadow 0.3s ease-in-out;
  animation: glowing 1s infinite alternate;
}

.spin {
  border-radius: 50%;
  box-shadow: none;
  animation: none;
}

.home_logo {
  display: block;
  position: absolute;
  width: 495px;
  height: 565px;
  cursor: pointer;
  left: -20px;
}

.home_header {
  position: relative;
  z-index: 10;
  color: white;
  font-size: 130px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
}

.home_descr {
  position: relative;
  display: block;
  z-index: 10;
  margin-top: 24px;
  margin-bottom: 80px;
  font-weight: 600;
  font-size: 30px;
  color: white;
  text-align: center;
}

.home_btn {
  text-decoration: none;
  padding: 16px 64px;
  font-size: 24px;
  font-weight: 800;
  line-height: 24px;
  border-radius: 50px;
  background: linear-gradient(180deg, #5200FF 0%, #6372FF 100%);
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  transition: background 0.3s linear;
  z-index: 100;
}

.home_btn:hover {
  background: linear-gradient(180deg, rgba(93, 21, 234, 0.96) 0%, #6372FF 100%);
}

@media (min-width: 2300px) {
  .glow-container {
    top: 230px;
  }

  .glow-container-spin {
    top: 230px;
  }

  .home_header {
    text-shadow: -1.5px 0 black, 0 2px black, 1px 0 black, 0 -1.5px black;
  }
}

@media (max-width: 1350px) {
  .home_header {
    font-size: 110px;
    text-shadow: -1.5px 0 black, 0 2px black, 1px 0 black, 0 -1.5px black;
  }

  .home_descr {
    font-size: 25px;
    margin-bottom: 60px;
  }

  .home_btn {
    padding: 14px 62px;
    font-size: 22px;
  }
}

@media (max-height: 1310px) {
  .glow-container {
    top: 5rem;
  }
}

@media (max-height: 1270px) {
  .home {
    padding: 165px 0;
  }
}

@media (max-height: 1170px) {
  .home {
    padding: 135px 0;
  }
}

@media (max-width: 1120px) {
  .glow-container{
    width: 465px!important;
    height: 465px!important;
    top: 3rem;
  }

  .glow-container-spin {
    position: absolute;
    top: 1rem;
  }

  .home_logo {
    top: -45px;
  }

  .home_header {
    font-size: 81px;
  }

  .home_descr {
    margin-top: 0;
    font-size: 25px;
    margin-bottom: 50px;
  }

  .home_btn {
    padding: 14px 62px;
    font-size: 22px;
  }
}

@media (max-height: 1040px) {
  .home {
    padding: 60px 0;
  }
}

@media (max-height: 900px) {
  .home {
    padding: 20px 0;
  }
}
@media (max-height: 900px) and (orientation: landscape) {
  .home {
    padding: 20px 0 2rem;
  }
}

@media only screen and (max-width: 1120px) and (orientation: landscape) {
  .glow-container{
    width: 250px!important;
    height: 250px!important;
    top: 2rem;
  }
  .glow-container-spin {
    position: absolute;
    top: 1rem;
  }
  .home_logo{
    width: 280px;
    height: 280px;
    left: -15px;
    top: -15px;
  }
}

@media (max-width: 908px) {
}

@media only screen and (max-width: 890px) and (orientation: landscape) {
}

@media (max-width: 890px) {
}

@media (max-width: 810px) {
  .home {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 8rem;
  }

  .home_header {
    font-size: 70px;
  }
}

@media (max-width: 772px) {
}

@media (max-width: 744px) {
  .home_header {
    font-size: 60px;
  }

  .home_descr {
    width: 80%;
  }
}

@media (max-width: 693px) {
}

@media (max-width: 675px) {
}

@media (max-width: 650px) {
}

@media (max-width: 630px) {
}

@media (max-width: 579px) {
}

@media (max-width: 545px) {
}

@media (max-width: 533px) {
}

@media (max-width: 465px) {
  .glow-container {
    width: 325px!important;
    height: 325px!important;
  }

  .glow-container-spin {
    width: 325px!important;
    height: 325px!important;
  }

  .home_logo {
    height: 360px;
    width: 360px;
    top: -10px;
  }

  .home_header {
    font-size: 40px;
    font-weight: 400;
    line-height: 51px;
  }

  .home_descr {
    width: 70%;
    margin-bottom: 33px;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
  }

  .home_btn {
    padding: 15px 35px;
    font-size: 20px;
    font-weight: 800;
    text-align: center;
  }
}

@media (max-width: 433px) {
}

@media (max-width: 396px) {
}

@media (max-width: 360px) {
}
